@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: "Plus Jakarta Sans", sans-serif;
    background-color: #262531;
  }
}

@layer components {
  .text-hero {
    @apply text-hero-mobile short:text-hero-mobile md:text-hero-desktop;
  }

  .text-body-1 {
    @apply text-body-1-mobile short:text-body-1-mobile md:text-body-1-desktop;
  }

  .text-primary {
    @apply bg-gradient-to-br from-[#FFA16B] to-[#F1BF79] bg-clip-text text-transparent;
  }

  .text-primary-dark {
    @apply bg-gradient-to-br from-[#F0A260] to-[#EB8A52] bg-clip-text text-transparent;
  }

  .text-secondary {
    @apply bg-gradient-to-br from-[#262531] to-[#1B1A22] bg-clip-text text-transparent;
  }

  .gradient-primary {
    background: linear-gradient(137.98deg, #f6c473 10.47%, #eb8951 88.18%);
  }
  .gradient-primary-btn {
    background: linear-gradient(137.98deg, #f6c473 10.47%, #eb8951 88.18%);
  }
  .gradient-primary-btn:hover {
    background: linear-gradient(137.98deg, #eb8951 10.47%, #f6c473 88.18%);
  }

  .gradient-secondary {
    background: linear-gradient(112.9deg, #1b1a22 0.1%, #262531 100.1%);
  }

  .gradient-tertiary {
    @apply bg-gradient-to-br from-[#1B1B23] to-[#272531];
  }

  .gradient-dark {
    background: linear-gradient(257.04deg, #17181c 81.29%, #221f23 126.93%);
  }

  .active-nav {
    @apply relative before:absolute before:left-0 before:top-1/2 before:h-full before:w-0.5 before:-translate-y-1/2 before:bg-[#FFA16B] before:content-[''];
  }
}

.special-btn {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: 30px;
  background: #262531;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.special-btn .btn-content {
  display: block;
  background: radial-gradient(107.5% 107.5% at 50% 215%, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01)),
    linear-gradient(93.11deg, rgba(46, 40, 31, 0.4) 8.26%, rgba(39, 33, 53, 0.4) 95.75%);
  position: relative;
  z-index: 5;
}
.special-btn:hover .btn-content {
  background: radial-gradient(84.92% 150% at 50% 138.75%, rgba(235, 137, 81, 0.16) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01)),
    linear-gradient(267.39deg, rgba(32, 32, 32, 0.4) 4.58%, rgba(40, 33, 54, 0.4) 94.74%);
}

.special-btn::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 400%;
  background-color: #fbb40079;
  box-shadow: 0 0 20px #ffad2a;
  animation: rotate 8s linear infinite;
  transform-origin: 0 0;
}

.special-btn::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #262531;
  border-radius: 30px;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.tag {
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: linear-gradient(to right, #ffa16b, #f1bf79);
}

.tag::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  z-index: -1;
  transition: 200ms;
  @apply gradient-secondary;
}

.tag::after {
  content: attr(data);
  @apply text-primary;
}

#team .slick-slider .slick-list .slick-track .slick-slide {
  padding: 20px;
}

.slick-arrow {
  display: none !important;
}

.flippy-container {
  margin-inline: auto !important;
}

/* FLIPPY */
.flippy-front,
.flippy-back {
  padding: 0 !important;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: none !important;
  @apply border border-orange-dark;
}
/* END OF FLIPPY */

.partners-image-wrapper a {
  @apply border-r border-b border-orange-dark;
}
@media (min-width: 768px) {
  .partners-image-wrapper a:nth-child(n + 9):nth-child(n + 1) {
    border-bottom: none; /* Remove top border for images 2, 3, and 4 */
  }

  .partners-image-wrapper a:nth-child(4n) {
    border-right: none; /* Remove right border for every 4th image */
  }
}

@media (max-width: 768px) {
  /* Reset border configurations for medium and larger screens */
  .partners-image-wrapper a {
    @apply border-x-transparent;
  }
}

@media (max-width: 768px) {
  h1 br,
  h2 br,
  h3 br,
  h4 br,
  p br {
    display: none;
  }
}

/* SWIPER */

.swiper-slide {
  width: max-content !important;
  height: max-content !important;
}

@media (max-width: 640px) {
  .swiper-slide:last-child {
    margin-right: 60px !important;
  }
}

@media (min-width: 640px) {
  .swiper-slide:first-child {
    margin-left: calc(calc(100vw - 640px) / 2) !important;
  }

  .swiper-slide:last-child {
    margin-right: calc(calc(100vw - 640px) / 2) !important;
  }
}

@media (min-width: 768px) {
  .swiper-slide:first-child {
    margin-left: calc(calc(100vw - 768px) / 2) !important;
  }

  .swiper-slide:last-child {
    margin-right: calc(calc(100vw - 768px) / 2) !important;
  }
}

@media (min-width: 1024px) {
  .swiper-slide:first-child {
    margin-left: calc(calc(100vw - 1024px) / 2) !important;
  }

  .swiper-slide:last-child {
    margin-right: calc(calc(100vw - 1024px) / 2) !important;
  }
}

@media (min-width: 1280px) {
  .swiper-slide:first-child {
    margin-left: calc(calc(100vw - 1280px) / 2) !important;
  }

  .swiper-slide:last-child {
    margin-right: calc(calc(100vw - 1280px) / 2) !important;
  }
}

@media (min-width: 1536px) {
  .swiper-slide:first-child {
    margin-left: calc(calc(100vw - 1536px) / 2) !important;
  }

  .swiper-slide:last-child {
    margin-right: calc(calc(100vw - 1536px) / 2) !important;
  }
}

/* END OF SWIPER */
